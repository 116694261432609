import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./relatie.scss"

const Relatie = () => {
  return (
    <Layout buttonrelatie={false}>
      <SEO
        title=" Zipperr® Relatie challenge"
        description="Relatiechallenge gedurende 6 weken met iedere week tips, video, oefeningen en luisterfragmenten"
      />
      <div className="containerPad bgalt">
        <h1 className={"relatietitel"} style={{}}>
          Doe je mee met de Zipperr® Relatie Challenge
        </h1>
        <h3 className={"relatiesubtitel"}>
          Graag willen we een positieve bijdrage leveren aan deze lastige tijd.
          Vandaar een deze relatiechallenge tegen een gereduceerde prijs in
          samenwerking met Profile Dynamics® en ReAttach:
        </h3>
        <div className={"checkboxter"}>
          <ul>
            <li>Gratis videocall, normaal 90 euro.</li>
            <li>
              Profile Dynamics®drijfverenanalyse, normaal 250,00 voor 2
              personen.
            </li>
            <li>
              Relatiechallenge gedurende 6 weken met iedere week tips, video,
              oefeningen en luisterfragmenten, normaal 150 euro.
            </li>
          </ul>
        </div>
        <div
          className={"containerPad flexyadjusted"}
          style={{ paddingBottom: "0px" }}
        >
          <div
            className={"half block contntr-2 highlightedText"}
            style={{ padding: "15px 20px" }}
          >
            <h2>Programma om je relatie sterk te verbeteren:</h2>

            <p>
              <strong>Week 1:</strong>
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Online invullen van de Profile Dynamics®drijfverenanalyse{" "}
              </li>
              <li>Luisteroefening 10 minuten gesprek</li>
              <li>Opdracht challenge voor deze week 1</li>
            </ul>

            <p>
              <strong>Week 2:</strong>
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Videocall : Uitleg rapport Profile Dynamics®drijfverenanalyse
                Aanleren van de emotieregulatieoefening voor meer rust en Focus
              </li>
              <li>Opdracht challenge voor week 2</li>
            </ul>

            <p>
              <strong>Week 3:</strong>
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>Video over geweldloze communicatie</li>
              <li>Tips en oefeningen</li>
              <li>Opdracht challenge voor week 3</li>
            </ul>
          </div>
          <div
            className={"half block contntr highlightedText shader"}
            style={{ padding: "15px 20px" }}
          >
            <h2 className={"hideonsmall"}>----</h2>
            <div className="contentblockslife">
              <p>
                <strong>Week 4:</strong>
              </p>
              <ul style={{ listStyleType: "disc" }}>
                <li>Luisteroefening psychologie achter een relatie</li>
                <li>Tips en oefeningen</li>
                <li>Opdracht challenge voor week 4</li>
              </ul>

              <p>
                <strong>Week 5:</strong>
              </p>
              <ul style={{ listStyleType: "disc" }}>
                <li>Video de vijf liefdestalen</li>
                <li>Tips en oefeningen</li>
                <li>Opdracht challenge voor week 5</li>
              </ul>

              <p>
                <strong>Week 6:</strong>
              </p>
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  Luisteroefening ouderlijk systeem, huidig gezinssysteem en
                  werksysteem
                </li>
                <li>Tips en oefeningen</li>
                <li>Opdracht challenge voor week 6</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={"highlightedText"}
          style={{
            width: "88.5%",
            margin: "0 auto",
            padding: "10px 25px",
            boxSizing: "border-box",
          }}
        >
          <p style={{ textAlign: "center" }}>
            <strong>Totaal 490 euro</strong>
          </p>
          <p style={{ textAlign: "center" }}>
            <strong>Aanbieding: 179,95 euro voor 2 personen</strong>
          </p>

          <p style={{ textAlign: "center" }}>
            <strong>
              <span>Je kunt je aanmelden via: </span>
              <br />
              telefoon<a href="tel:0653667567"> 06-53667567</a>
              <br />
              mail
              <a href="mailto:info@zipper.nl?SUBJECT=website-aanmelding-relatie">
                {" "}
                info@zipperr.nl
              </a>
            </strong>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Relatie
